
import axios from "@/plugins/axios.js"
import { useToast } from 'vue-toastification'
const toast = useToast()

const actions = {
  alertSuccess({}, message) {
      toast.success(message)
  },
  alertError({}, message) {
      toast.error(message)
  },
  changeLoaderValue({ commit }, val = false) {
    commit("TOGGLE_LOADER", val)
  },
  logoutUser({ commit, rootState }, action) {
    commit("LOGOUT_USER")
    return new Promise((resolve, reject) => {
      axios.get(`${rootState.domain}/api/logout`)
      .then((response) => {
          resolve(response)
      }).catch((error) => { reject(error) })
    })
  },
  universalFetch({ commit, rootState }, action) {
    return new Promise((resolve, reject) => {
      axios.get(action)
      .then((response) => {
          resolve(response)
      }).catch((error) => { reject(error) })
    })
  },

  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////
  fetchActiveUser({ commit, rootState }, action) {
    return new Promise((resolve, reject) => {
      axios.get("/account/show")
      .then((response) => {
          if(response.data.success){
            commit('UPDATE_USER_INFO', response.data.data)
          }
          resolve(response)
      }).catch((error) => { reject(error) })
    })
  },
  fetchCountries({ commit, rootState }, action) {
    return new Promise((resolve, reject) => {
      axios.get(`${rootState.domain}/api/countries`)
      .then((response) => {
        if(response.data.success){
            commit('SET_COUNTRIES', response.data.data)
        }
        resolve(response)
      }).catch((error) => { reject(error) })
    })
  },
  fetchLocalGovernments({ commit, rootState }) {
    if(rootState.localGovernments.length) return
    return new Promise((resolve, reject) => {
      axios.get(`${rootState.domain}/api/local-governments`)
        .then((response) => {
          if(response.data.success){
            commit('SET_LOCAL_GOVERNMENTS', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchBanks({ commit, rootState }) {
    if(rootState.banks.length) return
    return new Promise((resolve, reject) => {
      axios.get(`${rootState.domain}/api/banks`)
        .then((response) => {
          if(response.data.success){
            commit('SET_BANKS', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  loadStripe({}) {
    if(document.getElementById("StripeScript")){return }
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = `https://js.stripe.com/v3/`;
      script.async = true;
      script.id = "StripeScript"
      script.onreadystatechange = script.onload = function () {
        if (!script.readyState || /loaded|complete/.test(script.readyState)) {
          setTimeout(function () {
            resolve()
          }, 500)
        }
      }
      document.head.appendChild(script);
    })
  },
  getClientCountry({commit}, val = false){
    fetch('https://api.ipregistry.co/?key=tryout')
      .then(function (response) {
          return response.json();
      })
      .then(function (payload) {
        commit('SET_COUNTRY', payload.location.country);
      });
  },
}

export default actions
