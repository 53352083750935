import env from '../env.js'
import { getUserData } from '@/helpers/utils'

const state = {
    isLoading: false,
    AppActiveUser: getUserData(),
    domain: env.domain,
    localUserKey: env.localUserKey,
    appName: env.appName,
    config:{
        shortDesc: "Supporters group",
        appEmail: "support@umoeno.com"
    },
    scrollY: 0,
    windowWidth: window.innerWidth,
    countries: [],
    localGovernments: [],
    banks:[],
    dashboardData:{
        "group": {
            'friendly_id': '',
            'members_count': 0,
        },
        "user": {
          "id_card": "",
        },
    },
    lightbox:{
        index: null,
        visible: false,
        files: []
    },
}

export default state
