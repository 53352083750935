export default{
  methods:{
      absoluteUrl(path = ""){
        return this.$store.state.domain+path
      },
      isImageFile(ext){
        return  ['png','jpeg','svg','jpg','webp'].includes(ext ? ext.toLowerCase() : null)
      },
      showProfileImg(image){
        const data = { index: 0, visible: true, files: [ this.absoluteUrl(image)] }
        this.$store.commit('TOGGLE_LIGHT_BOX', data)
      },
      preText(content) {
        if(!content) return;
        const words = content.replace(/\n/g, ' <br>').split(' ');
        const formattedWords = [];
        words.forEach(word => {
          if (word.startsWith('http://') || word.startsWith('https://')  || word.startsWith('www.')) {
            formattedWords.push(`<a href="${word}" class="text-primary" target="_blank"> ${word} </a>`);
          } else {
            formattedWords.push(word)
          }
        });
        return formattedWords.join(' ')
      },
      statusBadge(status){
        if(!status) return;
        let codes = { active : '#0ec569', reviewed : '#41d051', paid: '#0ec569',  not_paid : '#000000',
                  draft: '#222222', pending: '#EA5455', approved: '#007d53', canceled: '#FF0000',
                  suspended: '#446ee7', rejected  : '#ff0002', closed  : '#ff0002'
        }
        return `<span style="background:${codes[status.toLowerCase()]}24; color:${codes[status]}; padding:0px 5px 2px 5px;line-height:1; border-radius:4px;">${status.replace(/_/g, ' ')}</span>`
      },
      scrollToView(elID){
        if(!elID){return}
        const yOffset = -200;
        const element = document.getElementById(elID);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
      },
      alertSuccess(text){
        this.$store.dispatch("alertSuccess", text)
      },
      alertError(text){
        this.$store.dispatch("alertError", text)
      },
      confirmDelete(option){
        return new Promise((resolve, reject) => {
          Swal.fire({
            title: option.title || "Are you sure?",
            text: option.text,
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonColor: "#f1556c",
            cancelButtonColor: "#111",
            confirmButtonText: "Delete it!",
          }).then((result) => {
            resolve(result)
          }).catch((error) => { reject(error) });
        })
      },
      confirmAction(option){
        return new Promise((resolve, reject) => {
          Swal.fire({
            title: option.title || "Confirm action!",
            html: option.text,
            // icon: "warning",
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonColor: "#225862",
            cancelButtonColor: "#111",
            confirmButtonText: "Yes, Proceed!",
          }).then((result) => {
            resolve(result)
          }).catch((error) => { reject(error) });
        })
      },
      exelFileExport(payload) {
        return new Promise((resolve, reject) => {
          this.$http.get(`${payload.url}?${new URLSearchParams(payload.data).toString()}`, { responseType: 'blob' })
          .then((response) => {
            resolve(response)
            if(response.status == 200){
              const timestamp = new Date().getTime();
              const filename =  (payload.filename || '') + timestamp + '.xlsx';
              
              const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
              const link = document.createElement('a');
              link.href = URL.createObjectURL(blob);
              link.download = filename;
              link.click();
            }
          }).catch((error) => { reject(error) })
        })
      },
      pdfFileExport(payload) {
        return new Promise((resolve, reject) => {
          this.$http.get(`${payload.url}?${new URLSearchParams(payload.data).toString()}`, 
          { responseType: 'arraybuffer' })
          .then((response) => {
            resolve(response)
            if(response.status == 200){
              const blob = new Blob([response.data], { type: 'application/pdf' });
              const url = URL.createObjectURL(blob);
              window.open(url, '_blank');
            }
          }).catch((error) => { reject(error) })
        })
      },
  },
}
