export default [
    {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
        path: '/main',
        redirect: '/',
        component: () => import('@/layouts/Main.vue'),
        children: [
            {
              path: '/',
              name: 'dashboard',
              component: () => import('@views/Dashboard.vue'),
              meta: {
                title: 'Dashboard',
                authRequired: true,
              }
            },
            {
              path: '/members',
              name: 'members',
              component: () => import('@views/members/Members.vue'),
              meta: {
                title: "Group Members",
                authRequired: true,
              },
            },
            {
              path: '/members/:memberId(\\d+)',
              name: 'member-show',
              component: () => import('@views/members/MemberShow.vue'),
              meta: {
                title: "View Member",
                authRequired: true,
              },
            },
            {
              path: '/notifications',
              name: 'notifications',
              component: () => import('@views/notification/NotificationList.vue'),
              meta: {
                title: "Notifications",
                authRequired: true,
              }
            },
            {
              path: '/account',
              name: 'account',
              component: () => import('@views/account/UserEdit.vue'),
              meta: {
              title: 'Profile',
              authRequired: true,
              },
            }
        ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
        path: '/full',
        component: () => import('@/layouts/Full.vue'),
        children: [
          {
            path: '/login',
            name: 'login',
            component: () => import('@/views/pages/Login.vue'),
            meta: {
              guestRequired: true,
              title: "Login"
            }
          },
          {
              path: '/error-404',
              name: 'error-404',
              component: () => import('@/views/pages/Error404.vue'),
              meta: {
                title: "404 - Page not Found"
              }
          },
          {
              path: '/error-500',
              name: 'error-500',
              component: () => import('@/views/pages/Error500.vue'),
              meta: {
                title: "500 - Server error"
              }
          },
        ]
    },
    // Redirect to 404 page, if no match found
    {
        path: '/:any(.*)',
        redirect: '/error-404'
    },

]